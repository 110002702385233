import React, { useState, useEffect } from 'react';
import styles from './index.module.css';

const Promolist = ({ regularPrice, i, discountedPrice, promoCodeNew }) => (
    <div>

      <div className={styles.total}>
        <p style={{ color: '#848484', marginTop: '0px' }} className={styles.projectorTitle}>
        Due on renewal
{' '}
{i + 1}
        </p>
        <p style={{ color: '#848484', marginTop: '0px' }} className={styles.totalPrice}>
        {`$${(parseFloat(regularPrice) - discountedPrice / 100).toFixed(2)}`}
        </p>
      </div>
    </div>
  );

export default Promolist;
