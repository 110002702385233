import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axios, dataFetcher, endpoints, baseUrl } from 'Api';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import useMyAxios from 'hooks/useMyAxios';
import { getAllActivePlans } from 'Api/apiFunctions';
import { ALLOWED_UPLOADS_COUNT } from 'Commons';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Spinner from 'Components/Spinner';
import { navigateTo } from '../../navigationHelper';
import CheckGreen from '../../Assets/check-circle.png';
import ClockPending from '../../Assets/clockpending.png';
import styles from './TrailVideoPopUp.module.css';
import image from '../../Assets/frnds.png';

function SuccessPopUp({ expressData, onHide, paymentStatus, paymentNavigation }) {
  const { userDetails, setAppSnackbar, getSubscription, autoSignIn } = useContext(AppContext);
  const { getToken } = useContext(AuthContext);
  const formatDate = (dateString, duration) => {
    const startDate = new Date(dateString);
    // eslint-disable-next-line
    startDate.setDate(startDate.getDate() + parseInt(duration)); // Add duration to date

    const day = startDate.getDate();
    const month = startDate.toLocaleString('en-US', { month: 'long' });
    const year = startDate.getFullYear();

    const suffix = day % 10 === 1 && day !== 11
      ? 'st'
      : day % 10 === 2 && day !== 12
      ? 'nd'
      : day % 10 === 3 && day !== 13
      ? 'rd'
      : 'th';

    return `${day}${suffix}, ${month} ${year}`;
  };

  const endDate = formatDate(
    expressData.datetime,
    expressData.is_trial ? expressData.plan_trial_duration : expressData.plan_duration
  );

  const spinerSmall = 'spinex';
  const [videoSkipped, setVideoSkipped] = useState(false);
  const [loader, setloader] = useState(false);
  const {
    response: plans,
    error: plansErr,
    loading: plansLoading,
    refetch: refetchPlans,
  } = useMyAxios(getAllActivePlans, null, 'ALL_ACTIVE_PLANS');
  const subscribePlan = async () => {
    setloader(true);
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/addPlan`;
      const requestBody = {
        token: myToken,
        plan_id: 11,
        is_free: 0,
        upgrade: true,
        trial: false,
      };
      const response = await axios.post(apiUrl, requestBody);
      setAppSnackbar({
        isVisible: true,
        type: 'success',
        message: 'Plan purchased successfully',
      });
      getSubscription();
      autoSignIn();
      onHide();
      setloader(false);
    } catch (error) {
      const responseData = error?.response?.data?.message;
      console.error('error', error);
      setAppSnackbar({
        isVisible: true,
        type: 'error',
        message:
          responseData || 'Oops! Something went wrong while purchasing plan',
      });
      setloader(false);
    }
  };
    const handleRetry = () => {
        onHide();
        navigateTo('/plans');
  };
  return (
    <div className={styles.bigframe}>
      <Box className={styles.main} borderRadius="10px">
        <Box
          display="flex"
          justifyContent="flex-end"
          position="absolute"
          width="100%"
          zIndex="1"
          padding="5"
          marginTop="5"
        >
<div
  onKeyPress={undefined}
  role="button"
  tabIndex={0}
  className={styles.topbtn}
  onClick={(e) => {
    if (onHide) onHide(e); // Always execute onHide
    if (paymentStatus) {
      if (paymentStatus === 'affliate' || paymentStatus === 'affliate_error') {
        paymentNavigation('/settings/manage-users'); // Navigate to Manage Users
      } else if (paymentStatus === 'success') {
        paymentNavigation('/dashboard'); // Navigate to Dashboard
      } else {
        paymentNavigation('/settings/billings-and-payments'); // Navigate to Billing
      }
    }
  }}
>

            Exit
</div>
        </Box>
        {/* <ReactPlayer
        className={styles.videoPlayer}
        url={promotionVideoUrl}
        playing
        muted
        loop
      /> */}
        <img src={image} className={styles.videoPlayer} alt="" />
        {!videoSkipped && (
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            height="auto"
            className={styles.textbox}
            borderRadius="10px"
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
              height="100%"
            >
              {paymentStatus === 'success' || paymentStatus === 'affliate' ? (
                <p className={styles.ultimate}>
                You have Unlocked the Ultimate Personal
                  <br />
                  Viewing Experience!
                </p>
              ) : (
                <p className={styles.ultimate}>
                  Your Ultimate Personal
                  <br />
                  Viewing Experience is yet to be Unlocked
                </p>
              )}

{paymentStatus === 'success' || paymentStatus === 'affliate' ? (
                <div className={styles.streamx}>
                 You upgraded to a
{' '}
                  <span className={styles.boldText}>
{expressData?.plan_name}
{' '}
plan!
                  </span>
{' '}
                  {/* for
                  {' '}
<span className={styles.boldText}>
  {expressData?.is_trial
    ? `${expressData?.plan_trial_duration} Days`
    : expressData?.plan_duration === '365'
    ? 'an Year'
    : expressData?.plan_duration === '30'
    ? 'a Month'
    : expressData?.plan_duration === '7'
    ? 'a Week'
    : expressData?.plan_duration === '36500'
    ? 'Lifetime'
    : `${expressData?.plan_duration} Days`}
</span>

{' '}
was
                  successful */}
<br />
                  You will have access to:
                </div>
              ) : (
                <div className={styles.streamx}>
                  Upgrade to
{' '}
                  <span className={styles.boldText}>Premium plan</span>
{' '}
for
<span className={styles.RedText}> an year</span>
{' '}
Unsuccessful
<br />
                </div>
              )}
              <div>
                <div className={styles.featureslist}>
                  <img
                    src={
                        paymentStatus === 'success' || paymentStatus === 'affliate' ? CheckGreen : ClockPending
                    }
                    alt=""
                  />
                  Unlimited Uploads
                </div>
                <div className={styles.featureslist}>
                  <img
                    src={
                        paymentStatus === 'success' || paymentStatus === 'affliate' ? CheckGreen : ClockPending
                    }
                    alt=""
                  />
                  Unlimited Cloud Storage
                </div>
                <div className={styles.featureslist}>
                  <img
                    src={
                        paymentStatus === 'success' || paymentStatus === 'affliate' ? CheckGreen : ClockPending
                    }
                    alt=""
                  />
                  Unlimited Enjoyment
                </div>
              </div>
              {!(paymentStatus === 'success' || paymentStatus === 'affliate') ? (
  <button onClick={handleRetry} className={styles.retry}>
    Retry your Purchase
  </button>
) : (
  expressData?.plan_duration !== '36500' && ( // Hide if plan_duration is '36500'
    <div className={styles.featureslist}>
      {expressData?.is_trial === false
        ? `Next renewal is on ${endDate}`
        : `Trial Ends in ${endDate}`}
    </div>
  )
)}

            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}
export default SuccessPopUp;
