import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams, NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { removeDummy, clearAllDummys, saveDummy, saveUploadInfo } from 'store/store';
import { baseUrl, firebaseCredentials } from 'Api';
import { ref, get, getDatabase, off, onValue, update } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { toast } from 'react-toastify';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Drawer,
  withStyles,
} from '@material-ui/core';
import { Pathname } from 'Routes';
import { AppContext, AuthContext } from 'Context';
import { ReactComponent as DashboardIcon } from 'Assets/home.svg';
import { ReactComponent as VideosIcon } from 'Assets/videos.svg';
import { ReactComponent as ContentIcon } from 'Assets/content.svg';
import { ReactComponent as SettingsIcon } from 'Assets/settings.svg';
import { ReactComponent as ChevronUp } from 'Assets/chevron-up.svg';
import { ReactComponent as Viewers } from 'Assets/viewer.svg';
import axios from 'axios';
import { InfoModal } from '../../Pages/private/dashboard/info-modal/index';
import { Logo } from '../logo';
import styles from './index.module.css';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: 'none',
    marginBottom: -1,
    height: 48,
    minHeight: 48,
    padding: '-',
    '&$expanded': {
      minHeight: 48,
      padding: '-',
    },
  },
  content: {
    margin: '0',
    alignItems: 'center',
    padding: '-',
  },
  expanded: {
    padding: '-',
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({ root: { padding: 0 } }))(MuiAccordionDetails);

const LinkAccordion = ({ name, links, icon: Icon }) => {
  const [isExpanded, setExpansion] = useState(false);
  const toggleExpansion = () => { setExpansion(!isExpanded); };

  return (
    <Accordion square expanded={isExpanded} onChange={toggleExpansion}>
      <AccordionSummary className={styles.slidingSidebarLink}>
        <Icon className={styles.slidingSidebarLinkIcon} />
        <span className={styles.slidingSidebarLinkName}>{name}</span>
        <ChevronUp className={styles.chevronUp} style={{ ...!isExpanded && { transform: 'rotateZ(180deg)' } }} />
      </AccordionSummary>
      <AccordionDetails className={styles.slidingSidebarLinksContainer}>
        {links.map(({ name: linkName, link }, idx) => (
          <NavLink
            activeClassName={styles.currentSlidingSidebarLink}
            className={cn({ [styles.slidingSidebarLink]: true, [styles.slidingSidebarChildLink]: true })}
            key={idx}
            to={link}
          >
            <span className={styles.slidingSidebarLinkName}>{linkName}</span>
          </NavLink>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export const Sidebar = ({ onClose, noBottomBar, noFixedSidebar, sidebarIsVisible }) => {
  const { iamUploading, topOrBottom, langText, fetchContent,
    setEncounterLoading, s3Data, fetchData, userAccessedData,
    handleManger, getSubscription, autoSignIn, getAccessChecker,
    setGetAccessChecker, firebaseDatabase, setUploadContainer,
    subscriptionDetails, setIamUploading, draftOfUpload, setActiveUploads, setUploadQueue, setOpen } = useContext(AppContext);
  const { isInActiveUser, getToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadPercentages = useSelector((state) => state.upload);
  const [isOpenInfoModal, setInfoModal] = useState(false);
  const [title, setTitle] = useState(' Are you sure you want to stop accessing the account?');
  const postData = async () => {
    try {
      const formData = new FormData();
      const myToken = getToken();
      formData.append('token', myToken);

      const response = await fetch(`${baseUrl}/getUserAccess`, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        setGetAccessChecker(data);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  const clearAllDummy = () => {
    dispatch(clearAllDummys());
  };

const updateUploadPercentages = (draft_id, data) => {
  dispatch(saveUploadInfo({ [draft_id]: data }));
};
const [responseDelay, setresponseDelay] = useState(false);
const managerReliving = async () => {
  localStorage.setItem('firstname', '');
  localStorage.setItem('lastname', '');
  try {
    const formData = new FormData();
    formData.append('token', getAccessChecker?.data?.user_token);
    const response = await axios.post(
      `${baseUrl}/getManagerUserLogs`,
      formData
    );
    clearAllDummy();
    handleManger(response.data);
    setresponseDelay(true);
    await postData();
    await autoSignIn();
    await getSubscription();
    setInfoModal(false);
    setresponseDelay(false);
    navigate('/settings/manage-users');
    // fetchContent();
  } catch (error) {
    setresponseDelay(false);
    setInfoModal(false);
    console.error(error);
  }
};
useEffect(() => {
  postData();
}, [userAccessedData]);
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  const data = useSelector((state) => state.deleteUpload);
  // const dataCallback = (snapshot) => {
  //   if (snapshot.exists()) {
  //     try {
  //       const dataObj = snapshot.val();
  //       data[s3Data.get('draft_id')] = dataObj[s3Data.get('draft_id')];
  //       dispatch(saveDeleteUpload({ data }));
  //     } catch (error) {
  //       console.error('Error processing data:', error);
  //     }
  //   }
  // };
  // onValue(databaseRef, dataCallback);
  // const unsubscribe = () => {
  //   off(databaseRef, 'value', dataCallback);
  // };
  const updateDummy = (dummy, status) => {
    if (status === 1) {
      dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
    } else {
      dispatch(removeDummy(dummy));
    }
  };
  const closeToast = (draft_id, message, type) => {
    toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
    setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
  };
  const deleteAdded = (userObject) => {
    setEncounterLoading(true);
    const draftIds = Object.keys(uploadPercentages);
    const deletePromises = [];
    draftOfUpload?.forEach((draftId) => {
      const uploadStatus = uploadPercentages[draftId];
      setActiveUploads([]);
      setUploadQueue([]);
      if (uploadStatus !== 'Please wait') {
        // const deletePromise = axios
        //   .post(`${baseUrl}/deleteDraftContent`, {
        //     token: getToken(),
        //     draft_id: [draftId],
        //     content_id: [],
        //   })
        //   .then((response) => {
        //     changeFieldFromFirebase(draftId, 'DELETED');
        //     updateDummy(draftId, 0);
        //     updateUploadPercentages(draftId, 'Cancelled');
        //     closeToast(draftId, 'Upload has been canceled by user', 'error');
        //     return response;
        //   })
        //   .catch((error) => {
        //     console.error(`Error deleting items for draftId ${draftId}:`, error);
        //     throw error;
        //   });
        changeFieldFromFirebase(draftId, 'DELETED');
        updateDummy(draftId, 0);
        updateUploadPercentages(draftId, 'Cancelled');
        closeToast(draftId, 'Upload has been canceled by user', 'error');
        // deletePromises.push(deletePromise);
      }
    });
    // Promise.all(deletePromises)
    //   .catch((error) => {
    //     console.error('Error deleting items:', error);
    //   });
  };

const handleExit = () => {
  setOpen(false);
  managerReliving();
  deleteAdded();
  fetchData();
  setIamUploading(true);
};
const exitFrom = () => {
  setInfoModal(true);
  const uploading = Object.values(uploadPercentages).some(
    (value) => typeof value === 'number' || value === 'Starting Upload'
  );
  if (uploading) {
    setTitle('Upload will cancel if you exit!');
  } else {
    setTitle(' Are you sure you want to stop accessing the account?');
  }
};
  const sideBarText = langText?.sidebar;
  const Links = isInActiveUser || subscriptionDetails?.current_plan === 'Free' ? [
    { name: sideBarText?.dashboard || 'Dashboard', sidebarName: 'Dashboard', icon: DashboardIcon, link: Pathname.dashboard },
    {
      name: sideBarText?.settings || 'Settings',
      sidebarName: 'Account Settings',
      icon: SettingsIcon,
      link: Pathname.getSettingsPath(Pathname.settings.billings),
      childLinks: [
        { name: 'Privacy', link: Pathname.getSettingsPath(Pathname.settings.privacy) },
        { name: 'Billing and Payments', link: Pathname.getSettingsPath(Pathname.settings.billings) },
      ],
    },
  ] : [
    { name: sideBarText?.dashboard || 'Dashboard', sidebarName: 'Dashboard', icon: DashboardIcon, link: Pathname.dashboard },
    { name: sideBarText?.content || 'Content', sidebarName: 'Video List', icon: VideosIcon, link: Pathname.yourVideos },
    { name: sideBarText?.layout || 'Layout', sidebarName: 'Content Layout', icon: ContentIcon, link: Pathname.contentLayout },
    {
      name: sideBarText?.viewers || 'Viewers',
      sidebarName: 'Viewers',
      icon: Viewers,
      link: Pathname.getViewersPath(Pathname.viewers.viewer),
      childLinks: [
        { name: 'Viewers', link: Pathname.getViewersPath(Pathname.viewers.viewer) },
        { name: 'Groups', link: Pathname.getViewersPath(Pathname.viewers.groups) },
        { name: 'Successors', link: Pathname.getViewersPath(Pathname.viewers.successors) },
      ],
    },
    {
      name: sideBarText?.settings || 'Settings',
      sidebarName: 'Account Settings',
      icon: SettingsIcon,
      link: Pathname.getSettingsPath(Pathname.settings.accounts),
      childLinks: [
        // { name: 'Account', link: Pathname.getSettingsPath(Pathname.settings.accounts) },
        // { name: 'Notifications', link: Pathname.getSettingsPath(Pathname.settings.notifications) },
        // { name: 'Privacy', link: Pathname.getSettingsPath(Pathname.settings.privacy) },
        // { name: 'Connected Accounts', link: Pathname.getSettingsPath(Pathname.settings.manageUsers) },
        // { name: 'Billing and Payments', link: Pathname.getSettingsPath(Pathname.settings.billings) },
        { name: (sideBarText?.accountSideBarTitle || 'Account'), link: Pathname.getSettingsPath(Pathname.settings.accounts) },
        { name: (sideBarText?.notificationSidebarTitle || 'Notifications'), link: Pathname.getSettingsPath(Pathname.settings.notifications) },
        { name: ('Billing and Payments' || sideBarText?.billPaymentSidebarTitle), link: Pathname.getSettingsPath(Pathname.settings.billings) },
        { name: (sideBarText?.privacySidebarTitle || 'Privacy'), link: Pathname.getSettingsPath(Pathname.settings.privacy) },
        { name: (sideBarText?.termsSidebarTitle || 'Terms of Use'), link: Pathname.getSettingsPath(Pathname.settings.termsAndConditions) },
        { name: (sideBarText?.subscriberSidebarTitle || 'Subscriber Agreement'), link: Pathname.getSettingsPath(Pathname.settings.subscriberAgreement) }
      ],
    },
  ];

  return (
    <>
      {
        isOpenInfoModal && (
          <InfoModal
            isVisible={isOpenInfoModal}
            title={title}
            onHide={() => { setInfoModal(false); }}
            continueButton="Cancel"
            fromPayment="true"
            responseDelay={responseDelay}
            // content="You are now deleting, all videos and photos will now be a draft until you
            // Add them to a new category. Are you sure you want to continue?"
            actionButton="Exit Anyway"
            action={() => handleExit()}
            isDelete
          />
        )
      }
      <div className={cn({ [styles.sidebarFill]: true, [styles.noBottomBar]: noBottomBar })}>
        <div className={styles.sidebarContent}>&nbsp;</div>
      </div>
      <aside
        className={cn({
          [styles.sidebar]: true,
          [styles.noBottomBar]: noBottomBar,
          [styles.noFixedSidebar]: noFixedSidebar,
        })}
      >
        <div className={styles.sidebarContent}>
        {Links.map(({ icon: Icon, link, name }, idx) => {
  const isActive = link === window.location.pathname;

  const className = isActive ? styles.currentLink : styles.sidebar;
  return (
    <NavLink
      key={idx}
      to={link}
      className={className}
      activeClassName={styles.currentLink} // Add the activeClassName for the active state
    >
      <Icon className={styles.linkIcon} />
      <span className={styles.linkName}>{name}</span>
    </NavLink>
  );
})}

        </div>
      </aside>
      {getAccessChecker?.success === true
        && (
            <div style={{ bottom: topOrBottom === false ? '0px' : '' }} className={styles.accessedCompny}>
              Accessed
              {' '}
              {userAccessedData?.firstname || localStorage.getItem('firstname')}
              {' '}
              {userAccessedData?.lastname || localStorage.getItem('lastname')}
              ’s Projector Account
              <button onClick={() => exitFrom()} className={styles.existButtun}>
                Exit
              </button>
            </div>
        )}
      <Drawer anchor="left" open={sidebarIsVisible} onClose={onClose}>
        <div className={styles.slidingSidebarContainer}>
          <div className={styles.slidingSidebar}>
            <div className={styles.logoContainer}>
              <Logo />
            </div>
            <div className={styles.slidingSidebarLinksContainer}>
              {Links.map(({ childLinks, link, sidebarName, icon: Icon }, idx) => (!childLinks
                ? (
                  <NavLink
                    activeClassName={styles.currentSlidingSidebarLink}
                    className={styles.slidingSidebarLink}
                    key={idx}
                    to={link}
                  >
                    <Icon className={styles.slidingSidebarLinkIcon} />
                    <span className={styles.slidingSidebarLinkName}>{sidebarName}</span>
                  </NavLink>
                )
                : (
                  <LinkAccordion name={sidebarName} key={idx} links={childLinks} icon={Icon} />
                )
              ))}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
