import React, { useContext, useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { AppContext, AuthContext } from 'Context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.css';

const CheckoutElementsStripe = () => {
    const { userDetails, setisFromAffl, isFromAffl, setAppSnackbar, bigUserData, expressCheckout, isTrail, apiCALL, expressloading, setExpressLoading, screatId, setScreatId } = useContext(AppContext);
  const stripe = useStripe();
    const navigate = useNavigate();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/access`,
      },
      redirect: 'if_required', // Prevents automatic redirection
    });

    console.log('Payment Response:', { error, paymentIntent });

    if (error) {
      console.error('Payment failed:', error);
    } else if (paymentIntent) {
      expressCheckout(screatId);
      console.log('Payment successful:', paymentIntent);
      if (paymentIntent.status === 'succeeded') {
        navigate('/access');
        expressCheckout(screatId);
      }
    }

    setLoading(false);
  };

  return (
    <form className={styles.paymentUi} onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          layout: 'tabs', // Enables multiple payment method tabs
          paymentMethodOrder: ['card', 'google_pay', 'apple_pay'],
        }}
      />
      <button className={styles.paybutt} type="submit" disabled={loading || !stripe || !elements}>
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
    </form>
  );
};

export default CheckoutElementsStripe;
