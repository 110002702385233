import React, { useContext, useState, useEffect } from 'react';
import { AppContext, AuthContext } from 'Context';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutElementsStripe from './CheckoutElementsStripe';
import stripeCredentials from '../../../../../../../Api/stripeCredentials.json';
import PageLayout from '../page-layout';

const stripePromise = loadStripe(
  'pk_live_51OyTOEKaFrbWdXakXgNskBU4TmXmqTIP6thT1vf8LjiR1FADapz2jX6Fi5a1h8KpG7hCAiEK9EnlrKbH7wDVGjOs00ReGilVdx'
);

const StripeElemets = () => {
  const { userDetails, setisFromAffl, isFromAffl, setAppSnackbar, screatId } = useContext(AppContext);
  const options = {
    clientSecret: screatId,
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: '#0570DE',
        colorBackground: '#FFFFFF',
        colorText: '#30313D',
        colorDanger: '#DF1B41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
        // See all possible variables below
      }
    },
  };
    return (
          <Box bgcolor="#FAFBFC">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutElementsStripe />
      </Elements>
          </Box>
  );
};

export default StripeElemets;
