import React, { useState, useContext, useEffect } from 'react';
import { AppContext, AuthContext } from 'Context';
import './UserM.css';
import { baseUrl } from 'Api';
import axios from 'axios';
import Edit from '../../Assets/edit.png';

function UserData({ getToken, fetchData, data }) {
  const { isTokenChange } = useContext(AppContext);
  useEffect(() => {
    fetchData();
  }, [isTokenChange]);
  const [loader, setLoader] = useState([]);

  const handleRemove = async (Id, index) => {
    try {
      const myToken = await getToken();
      const apiUrl = `${baseUrl}/removeInviteManager`;
      const requestBody = {
        token: myToken,
        id: Id,
      };
      setLoader((prevLoader) => {
        const updatedLoader = [...prevLoader];
        updatedLoader[index] = true;
        return updatedLoader;
      });
      const response = await axios.post(apiUrl, requestBody);
      fetchData();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader((prevLoader) => {
        const updatedLoader = [...prevLoader];
        updatedLoader[index] = false;
        return updatedLoader;
      });
    }
  };

  return (
    <div className="">
      <div className="the_Hieghter">
      {data?.data?.map((obj, i) => (
        <div className="flexter" key={i}>
          <div className="flexterson">
          <div className="rounder">{obj?.firstname?.charAt(0)?.toUpperCase()}</div>
          <div>
            <p className="UserName">
              {obj?.firstname?.charAt(0)?.toUpperCase() + obj?.firstname?.slice(1)}
              {' '}
              {obj?.lastname?.charAt(0)?.toUpperCase() + obj?.lastname?.slice(1)}
            </p>
            <p className="Usermail">{obj.email}</p>
          </div>
          </div>
          {/* <img className="edit" src={Edit} alt="" /> */}
          <button className="remove" onClick={() => handleRemove(obj.id, i)}>
          {loader[i] === true ? 'Loading' : 'Remove'}
          </button>
        </div>
        ))}
      </div>
    </div>
  );
}

export default UserData;
