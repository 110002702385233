/* eslint-disable radix */
import { useState, useEffect, useContext, useMemo } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import { AppContext } from 'Context';
import { useDispatch } from 'react-redux';
import { baseUrl, dataFetcher, endpoints, getToken } from 'Api';
import { setDidCardAdded } from 'store/store';
import PageLayout from './components/page-layout';
import FormSection from './components/form-section';
import PaymentField from './components/payment-field';

function Page({ closeAddCard, userId }) {
  const { setAppSnackbar, getSubscription, langText, cardAdded, setcardAdded, cardPayId, cardUpdate, cardUpdateBoolean } = useContext(AppContext);
  const dispatch = useDispatch();

  const addCardText = langText?.settings?.billPayment?.paymentMethod?.cardDetails;
  const [cardNumber, setCardNumber] = useState(undefined);
  const [expirationDate, setExpirationDate] = useState(undefined);
  const [cvvNumber, setCvvNumber] = useState(undefined);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [paymentIsValid, setPaymentValidity] = useState(false);

  const [cardNumberStripe, setCardNumberStripe] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');

  const [error, setError] = useState(null);
  const [cvc, setCvc] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [updateMonth, setUpdateMonth] = useState(cardPayId?.card?.exp_month?.toString().padStart(2, '0'));
  const [updateYear, setUpdateYear] = useState(cardPayId?.card?.exp_year?.toString().slice(-2));
  const [monthyear, setMonthyear] = useState(`${updateMonth} / ${updateYear}`);
  const [isItSame, setisItSame] = useState(true);

  useEffect(() => {
    const defaultMonth = cardPayId?.card?.exp_month?.toString().padStart(2, '0');
    const defaultYear = cardPayId?.card?.exp_year?.toString().slice(-2);

    const isCvcFilled = cvc.trim() !== ''; // Check if cvc is filled
    const isMonthYearFilled = updateMonth && updateYear; // Ensure both month and year are filled
    const isDifferentFromDefault = updateMonth !== defaultMonth || updateYear !== defaultYear; // Ensure values are not the same as defaults

    if (isMonthYearFilled && isDifferentFromDefault) {
      setisItSame(false); // All conditions met, set isItSame to false
    } else {
      setisItSame(true); // Conditions not met, reset to true
    }
  }, [cvc, updateMonth, updateYear, cardPayId]);
  const callStripeAPI = async () => {
    const datax = new URLSearchParams();
    datax.append('card[number]', cardNumberStripe);
    datax.append('card[exp_month]', expiryMonth);
    datax.append('card[exp_year]', expiryYear);
    datax.append('card[cvc]', cvc);
    try {
      const response = await axios.post('https://api.stripe.com/v1/tokens', datax, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer pk_live_51OyTOEKaFrbWdXakXgNskBU4TmXmqTIP6thT1vf8LjiR1FADapz2jX6Fi5a1h8KpG7hCAiEK9EnlrKbH7wDVGjOs00ReGilVdx',
        },
      });
      return response.data;
    } catch (error) {
      setAppSnackbar({ isVisible: true, type: 'error', message: error?.response?.data?.error?.message });
    }
  };
  const updateCardDetails = async () => {
    try {
        const payload = {
          method_id: cardPayId?.id,
          exp_month: updateMonth,
          exp_year: updateYear,
          cvc,
          token: getToken()
          // zip_code: zipcode
        };
        const response = await axios.post(`${baseUrl}/updatePaymentMethods`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        getSubscription();
        closeAddCard();

        setAppSnackbar({ isVisible: true, type: 'success', message: response?.data?.message, duration: 1500 });
        return response.data;
    } catch (error) {
        console.error('Error posting card details:', error?.response?.data?.message);
        setcardAdded(false);
        setAppSnackbar({ isVisible: true, type: 'error', message: error?.response?.data?.message });
        throw error;
    }
};
  const fetcher = async (url, number, exp_month, exp_year, cvc) => {
    try {
      const stripeToken1 = await callStripeAPI();
      const stripeToken2 = await callStripeAPI();
      const stripeTokenId1 = stripeToken1?.id;
      const stripeTokenId2 = stripeToken2?.id;
      if (!stripeTokenId1 || !stripeTokenId2) {
        throw new Error('Failed to get both Stripe tokens');
      }
      const payload = {
        card_token_alternative: stripeTokenId1,
        card_token: stripeTokenId2,
        userId,
        managerCard: '0',
        zip_code: zipcode
      };
      const response = await dataFetcher(url, payload);
      return response;
    } catch (error) {
      console.error('Error in fetcher:', error);
      throw error;
    }
  };

  const { isValidating: addingCard, mutate: addCard } = useSWR([
    endpoints.addPaymentMethods,
    cardNumberStripe,
    expiryMonth,
    expiryYear,
    cvc
  ], {
    fetcher,
    onSuccess: ({ success, message }) => {
      if (success) {
        getSubscription();
        closeAddCard();
        setAppSnackbar({
          isVisible: true, type: 'success', message: 'New card added successfully'
        });
        dispatch(setDidCardAdded(true));
        // setcardAdded(false);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message,
        });
      }
    },
    onError: (error) => {
      const responseData = error;
      setcardAdded(false);
      // setAppSnackbar({ isVisible: false, type: 'error', message: responseData?.message });
    },
  });
  const digits = cardNumberStripe.replace(/\D/g, ''); // Remove non-digit characters
  const isAmex = digits.startsWith('34') || digits.startsWith('37');
  const cardNumberIsValid = useMemo(() => {
    if (isAmex) {
      return cardNumberStripe?.length === 18 || cardNumberStripe?.length === 19;
    }
    return cardNumberStripe?.length === 19;
  }, [cardNumberStripe]);

  const cvvNumberIsValid = useMemo(() => {
    const digits = cardNumberStripe.replace(/\D/g, '');
    const isAmex = digits.startsWith('34') || digits.startsWith('37');
    return isAmex ? cvc?.length === 4 : cvc?.length === 3;
  }, [cvc, cardNumberStripe]);

  const expirationDateIsValid = useMemo(() => (expiryMonth?.length && expiryYear?.length === 2), [expiryMonth && expiryYear]);

  useEffect(() => {
    setPaymentValidity(cardNumberIsValid && cvvNumberIsValid && expirationDateIsValid);
  }, [cardNumberIsValid, cvvNumberIsValid, expirationDateIsValid]);
  useEffect(() => {
    if (paymentIsValid) {
      setIsFormFilled(true);
    }
  }, [paymentIsValid, isItSame]);
useEffect(() => {

}, [updateMonth, updateYear]);
return (
    <PageLayout
      continueButtonText={cardAdded ? 'Adding card' : (addCardText?.addButton || 'Add card')}
      formIsFilled={paymentIsValid || (!isItSame && cvc.length > 2)}
      onContinue={() => {
        setcardAdded(true);
        cardUpdateBoolean ? updateCardDetails()
        : addCard();
      }}
      handleClose={closeAddCard}
      subTitle=""
      title={addCardText?.title || 'Enter card details'}
      addingCard={cardAdded}
    >
      <FormSection title={addCardText?.description || 'Payment Information'}>
{cardUpdateBoolean ? (
        <PaymentField
        error={error}
        setError={setError}
        stripe
        cvc="***"
        setCvc={setCvc}
        isAmex={isAmex}
        expiryYear={updateYear}
        setExpiryYear={setUpdateYear}
        inputValue={monthyear}
        setInputValue={setMonthyear}
        expiryMonth={updateMonth}
        zipcode={zipcode}
        setZipCode={setZipCode}
        setExpiryMonth={setUpdateMonth}
        cardUpdateBoolean={cardUpdateBoolean}
        cardNumberStripe={`***** **** ${cardPayId?.card?.last4}`}
        setCardNumberStripe={setCardNumberStripe}
        onCardNumberChange={setCardNumber}
        onCvvNumberChange={setCvvNumber}
        onExpirationDateChange={setExpirationDate}
        setValidity={setPaymentValidity}
        />
) : (
  <PaymentField
  error={error}
  setError={setError}
  stripe
  zipcode={zipcode}
  setZipCode={setZipCode}
  cvc={cvc}
  setCvc={setCvc}
  isAmex={isAmex}
  expiryYear={expiryYear}
  setExpiryYear={setExpiryYear}
  inputValue={inputValue}
  setInputValue={setInputValue}
  expiryMonth={expiryMonth}
  setExpiryMonth={setExpiryMonth}
  cardNumberStripe={cardNumberStripe}
  setCardNumberStripe={setCardNumberStripe}
  onCardNumberChange={setCardNumber}
  onCvvNumberChange={setCvvNumber}
  onExpirationDateChange={setExpirationDate}
  setValidity={setPaymentValidity}
  />
)}

      </FormSection>
    </PageLayout>
  );
}

export default Page;
