import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import useSWR from 'swr';
import { ref, getDatabase, off, onValue, update } from 'firebase/database';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CloseIcon from '@material-ui/icons/Close';
import { dataFetcher, endpoints, axios, baseUrl } from 'Api';
import { ReactComponent as RightArrow } from 'Assets/category-right-arrow.svg';
import { ReactComponent as LeftArrow } from 'Assets/category-left-arrow.svg';
import { ProfilePage, ProjectorShimmer } from 'Components';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import ImageGroup from 'Components/ImageGroup/ImageGroup';
import ViewMyProjector from 'Components/ImageGroup/ViewMyProjector';
import { getUserContents } from 'Api/apiFunctions';
import useMyAxios from 'hooks/useMyAxios';
import FixedProgressBar from 'Components/header/components/FixedProgressBar';
import DelayedProgressBar from 'Components/DelayedProgressBar';
import ThreeDotLoader from 'Components/ThreeDotLoader';
import { WhatsNew } from '../my-profile/Component/WhatsNew/whatsNew';
import { ImageBanner } from '../my-profile/Component/ImageBanner/imageBanner';
import { AlbumBanner } from '../my-profile/Component/AlbumBanner/albumBanner';
import styles from './index.module.css';
import BottomBar from './BottomBar';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.5,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const isTrueValue = true;

const CustomRightArrow = ({ onClick, layoutType }) => ((
  <RightArrow
    fontSize="large"
    onClick={onClick}
    aria-label="Go to next slide"
    className={layoutType === 3 || layoutType === 4 ? 'react-multiple-carousel__arrow react-multiple-carousel__arrow--rightx layout_arrow_category' : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--rightx custom_arrow_category'}
  />
));

const CustomLeftArrow = ({ onClick, layoutType }) => ((
  <LeftArrow
    onClick={onClick}
    aria-label="Go to next slide"
    className={layoutType === 3 || layoutType === 4 ? 'react-multiple-carousel__arrow react-multiple-carousel__arrow--leftx layout_arrow_category' : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--leftx custom_arrow_category'}
  />
));
export const CONTENT_TYPES = {
  categories: 'categories',
  photo_albums: 'photo_album',
  playlists: 'playlists',
};

export const Profile = () => {
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const navigate = useNavigate();
  useEffect(() => {
sessionStorage.setItem('navigateProfile', window.location.pathname);
  }, [window.location.pathname]);
  const { setAppSnackbar, userDetails, contextLoading, setContextLoading, setLoadingState, langText, dataOfCategory, setdataOfCategory, profileName, setProfileName, amICalled,
    setTopOrBottom, setcategoryLoader, loader, setLoader, categoryLoader, profileLoader, projectorOwner, profileDetails, setNeedToShowETA, firebaseDatabase } = useContext(AppContext);
  const urlParams = useParams();
  const profileID = urlParams?.profileID || userDetails.myId;
  const isMyProfile = !urlParams?.profileID;
  const profileText = langText?.viewProjector;
  const scrollPageRef = useRef(null);
  const [selectedCategoryID, setSelectedCategoryID] = useState(undefined);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shimmerLoading, setShimmerLoading] = useState(true);
  const [profileHeroImage, setProfileHeroImage] = useState(undefined);
  const [resumeWatching, setResumeWatching] = useState([]);
  const [resumeMe, setResumeMe] = useState();
  const [playlist, setPlaylist] = useState();
  const [categories, setCategories] = useState([]);// categories box on top
  const [subCategories, setSubCategories] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [orderedData, setOrderedData] = useState(undefined);
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState(undefined);
  const [showArrow, setArrows] = useState(false);
  const [isPublicUser, setPublicUser] = useState(false);
  const [layoutType, setLayoutType] = useState(1);
  const [layoutData, setLayoutData] = useState(undefined);
  const [haveData, checkData] = useState(undefined);
  const [categoryStyleId, setCategoryStyleId] = useState(undefined);
  const [thumbnail, setThumbnail] = useState(undefined);
  const limit = 70; // categories video content limit
  const [start, setStart] = useState(1);
  const [startPL, setStartPL] = useState(1);
  const content_limit = 25;// photo albums limts & categories list limit
  const [content_start, setContentStart] = useState(1);
  const [content_startPL, setContentStartPL] = useState(1);
  const [basicData, setBasicData] = useState();
  const [contentData, setContentData] = useState();
  const [hasContent, setHasContent] = useState(false);
  const { getToken } = useContext(AuthContext);
  useEffect(() => {
    setTopOrBottom(false);
  }, []);

  const fetchData = async () => {
    try {
      let api = 'https://contents.projector.app/contents/getContentDetails';
      if (window.location.origin.includes('preprod') || window.location.origin.includes('localhost')) {
        api = 'https://contents.projector.app/contents/preprod/getContentDetails';
      }
      const response = await axios.post(api, {
        projector_id: profileID,
        user_id: localStorage.getItem('id')
      });
      setContentData(response?.data);
      setcategoryLoader(false);
    } catch (error) {
      const responseData = error?.response?.data;
      setcategoryLoader(false);
    }
  };
  const fetchResumeData = async () => {
    try {
      const response = await axios.post(`${baseUrl}/v1/getUserContentsResumes`, {
        user_id: profileID,
      });
      setResumeMe(response?.data);
      // setcategoryLoader(false);
    } catch (error) {
      const responseData = error?.response?.data;
      // setcategoryLoader(false);
    }
  };

  const callForUpdate = () => {
    const apiUrl = `${baseUrl}/v1/loadUserContents`;
    const postData = {
      token: getToken(),
      user_id: profileID,
      pass_from: 'web on payload'
    };
    axios.post(apiUrl, postData)
      .then((response) => response.data)
      .catch((error) => {
        console.error('err', error);
      });
  };

  useEffect(() => {
    callForUpdate();
    setcategoryLoader(true);
    setLoader(true);
    fetchResumeData();
    fetchData();
  }, [profileID]);
  const path = window.location.origin === 'https://projector.app' ? 'projector_contents' : 'pre_projector_contents';
  useEffect(() => {
    const databaseRefGetVideos = ref(firebaseDatabase, `${path}/${localStorage.getItem('id')}`);
    const dataCallbackVideos = (snapshot) => {
      if (snapshot.exists()) {
        // fetchResumeData();
        fetchData();
      }
    };
    onValue(databaseRefGetVideos, dataCallbackVideos);
    return () => {
      off(databaseRefGetVideos, 'value', dataCallbackVideos);
    };
  }, []);
  const { mutate: getMyPausedList } = useSWR([endpoints.getMyPausedList, profileID], {
    fetcher: (url, user_id) => dataFetcher(url, { user_id }),
    onSuccess: ({ success, paused, message }) => {
      if (success) {
        setcategoryLoader(false);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: message || 'Oops! Something went wrong' });
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong' });
    },
  });
  const { mutate: clearResumeWatching } = useSWR([endpoints.clearResumeList, profileID], {
    fetcher: (url, user_id) => dataFetcher(url, { user_id }),
    onSuccess: ({ success, message }) => {
      if (success) {
        fetchResumeData();
        fetchData();
        getMyPausedList();
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: error?.response?.data?.message || 'Oops! Something went wrong' });
    },
  });

  useEffect(() => {
    getMyPausedList();
  }, [profileID]);

useEffect(() => {
  setNeedToShowETA(false);
  setTimeout(() => {
    if (contextLoading) {
      setContextLoading(false);
    }
  }, 3000);
}, []);

// const uniqueContentTypes = contentData?.data?.map((item) => item?.content_type);

const uniqueContentTypes = contentData?.data
  ?.sort((a, b) => a.order_number - b.order_number)
  ?.map((item) => item?.content_type);

const arrangedContent = uniqueContentTypes?.map((contentType) => {
  const item = contentData?.data?.find((item) => item?.content_type === contentType);
  switch (contentType) {
    case 'resume_watching':
      return (
                    <ViewMyProjector
                    isGrid
                    setLoading={setLoading}
                    title={profileText?.resumeWatching || 'Resume Watching'}
                    responseData={resumeMe?.data[0]}
                    setShimmerLoading={setShimmerLoading}
                    setResumeMe={setResumeMe}
                    clearResumeWatching={clearResumeWatching}
                    type="Resume Watching"
                    />
      );
    case 'photos_albums':
      return (
                    <ViewMyProjector
                    imageData={contentData?.data?.find((item) => item?.content_type === 'photos_albums')}
                    isphoto="photo"
                    isGrid
                    type={CONTENT_TYPES.photo_albums}
                    title={profileLoader === false ? `${profileDetails?.firstname}'s Photo Albums` : 'Photo Albums'}
                    />
      );
    case 'playlists':
      return (
        <ViewMyProjector
        responseData={contentData?.data?.find((item) => item?.content_type === 'playlists')}
        isGrid
        />
      );
    case 'categories': {
      const newData = contentData?.data?.map((item) => ({
        ...item,
        data: item?.data?.sort((a, b) => a.order_number - b.order_number),
      }));
      return (
        <ViewMyProjector
          responseData={newData?.find((item) => item?.content_type === 'categories')}
          isGrid
          type={CONTENT_TYPES.categories}
        />
      );
    }
    case 'all_videos':
      return (
        <ViewMyProjector
        responseData={contentData?.data?.find((item) => item?.content_type === 'all_videos')}
        isGrid
        type="All Videos"
        />
      );
    default:
      return null;
  }
});

// Function to check if any content data is non-empty
const checkContent = (contentData) => contentData?.data?.some((item) => item?.data?.length > 0);

// Check content and update state
useEffect(() => {
  setHasContent(checkContent(contentData));
}, [contentData]);

useEffect(() => {
  if (contentData) {
    setLoader(false);
  }
}, [hasContent, setLoading]);

  const categoryHead = contentData?.data?.find((item) => item?.content_type === 'categories')?.data;
  return (
    <>
    {loader && (
    <FixedProgressBar />
    )}
    <BottomBar />
      {
        categoryLoader === true
        ? <FixedProgressBar />
        : (
          <ProfilePage
          containerClassName={cn({ [styles.pageContainer]: true, [styles.pageContainerVideoBanner]: layoutType === 4 })}
          mainClassName={cn({ [styles.mainContent]: true, [styles.loading]: loading })}
          userName={profileName}
          isProfileHeader
          userProfileLink={Pathname.getFriendProfilePath(profileID)}
          videoBanner={layoutType === 4}
          layoutData={layoutData}
          >
          {
            profileLoader === true ? (
              <div className={styles.ending}>
                <ThreeDotLoader />
              </div>
            ) : (
              <div className={styles.headingText}>
                {profileDetails?.firstname === 'Tutorials' ? profileDetails?.firstname : `${profileDetails?.firstname?.trim()}'s Projector`}
              </div>
            )
          }

          {categoryHead?.length > 0 && loader === false && (
            <div className={cn({ [styles.imageGroup]: true, [styles.layoutImageGroup]: (layoutType === 3 || layoutType === 5) && (categoryStyleId === 3 || categoryStyleId === 4), [styles.videoBannerImageGroup]: layoutType === 4 && (categoryStyleId === 3 || categoryStyleId === 4) })}>
              <div className={styles.imageGroupImagesWrapper}>
                {/* <div className={styles.imageGroupImagesContainer}> */}
                <div
                  onMouseEnter={() => setArrows(true)}
                  onMouseLeave={() => setArrows(false)}
                  className={cn({ [styles.imageGroupImagesContainer]: true, [styles.isGrid]: true })}
                >
                 <div className={styles.imageGroupImagesWrapper}>
                  <Carousel
                    arrows
                    showDots={false}
                    responsive={responsive}
                    ssr={isTrueValue} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={isTrueValue}
                    // customTransition="all .5"
                    // transitionDuration={500}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass={categoryStyleId === 3 || categoryStyleId === 4 ? 'category-item' : 'image-item_catergoryViewMyProj'}
                    customRightArrow={<CustomRightArrow layoutType={layoutType} />}
                    customLeftArrow={<CustomLeftArrow layoutType={layoutType} />}
                  >
                    {categoryHead?.map(({ id, title, icon, imagekit_icon }, idx) => (
                      <div
                        style={{
                          backgroundImage: `linear-gradient(
                          rgba(0, 0, 0, 0.5),
                          rgba(0, 0, 0, 0.5)
                        ),url(${imagekit_icon})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          fontWeight: '700'
                        }}
                        className={cn({
                          [styles.imageGroupImageLink]: true,
                          [styles.categoryButton]: true,
                          [styles.active]: selectedCategoryID === id,
                          [styles.textAlignFit]: true,
                          [styles.bannerTypeCategoryButton]: categoryStyleId === 3 || categoryStyleId === 4,
                          [styles.rounded]: categoryStyleId === 2 || categoryStyleId === 4
                        })}
                        key={idx}
                        tabIndex="0"
                        role="button"
                        onKeyPress={undefined}
                        onClick={() => {
                          setSelectedCategoryID(id);
                          setSelectedCategoryTitle(title);
                          setThumbnail(icon);
                          localStorage.setItem('prevlocation', 'innerpage');
                          navigate('/innerpage', { state: { title, id, icon, profile_id: profileID } });
                        }}
                      >
                        {title}
                      </div>
                    ))}
                  </Carousel>
                 </div>
                </div>
              </div>
            </div>
          )}
            <div>
                { hasContent ? (
                  <>
                  {arrangedContent}
                  </>
                ) : (
                <h2 style={{ margin: '0', textAlign: 'center', color: 'white', fontSize: '1.5rem', fontWeight: '500', padding: '10rem' }}>No Content...</h2>
                )}
            </div>
          </ProfilePage>
          )
      }
    </>
  );
};
