import { Box, Button, ButtonBase } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './plansCard.module.css';
import markBlack from '../Assets/mark-black.svg';
import TickCircle from '../Assets/check-circle.png';

function PlansCard({ product_name, amount, has_trial, currency, subtitle, description, price_description, hideToggle,
    setIsSwitchPlan,
    isSwitchPlan, handleSwitchToggle, trialDays, intravalDays, needToggle }) {
    return (
        <Box>
            <div className={styles.planHead}>
                <div className={styles.planName}>
                    <p>{product_name}</p>
                    {/* <span>{subtitle}</span> */}
                </div>

                <div className={styles.planPrice}>
                    <p>
                        $
                        {amount}
                    </p>
                    <span>{price_description}</span>
                </div>
            </div>

            <ul className={styles.planBody}>
                { description?.split(',').map((feature, index) => (
                        <li key={index}>
                            <span className={styles.feat}>{feature}</span>
                            <img className={styles.tickster} src={TickCircle} alt="tick_mark" />
                        </li>
                      ))}

            </ul>
{ needToggle && (
<div className={styles.toogleAbsolute}>
            {hideToggle !== '3' && (
 <div className={styles.toggleContainer}>
      <div
        className={styles.toggleSwitch}
        onKeyPress={undefined}
        role="button"
        tabIndex={0}
        onClick={handleSwitchToggle}
      >
              <p className={styles.backText}>Monthly</p>
              <p className={styles.backText}>Annually</p>
        <div
          className={`${styles.toggleButton} ${isSwitchPlan === '1' ? styles.yearly : styles.monthly}`}
        >
          <span className={styles.toggleText}>{isSwitchPlan === '1' ? 'Annually' : 'Monthly' }</span>
        </div>
      </div>
 </div>
          )}
</div>
)}
            <div className={styles.absoluteButton}>
            { has_trial === '1' && (
                <Link to="/newSign-up" className={styles.trail}>
                    Start
{' '}
{trialDays}
{' '}
day trial
                </Link>
            )}
            <Link
                to="/newSign-up"
                className={styles.buyNow}
            >
                Buy Now
            </Link>
            </div>
        </Box>
    );
}

export default PlansCard;
