import React, { useMemo, useState, useContext, useEffect } from 'react';
import { AppContext, AuthContext } from 'Context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from 'Routes';
import cn from 'classnames';
import { baseUrl, firebaseCredentials, axios } from 'Api';
import { FormGroup, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import RotatingLoader from 'Components/RotatingLoader';
import { CheckRounded } from '@material-ui/icons';
import PageLayout from './components/page-layout';
import FormSection from './components/form-section';
import CredentialField from './components/credential-field';
// eslint-disable-next-line
import { InfoModal } from '../../../../private/dashboard/info-modal/index';
import NameField from './components/name-field';
import PriceSectionLayout from './components/checkout-price-layout';
import logo from '../../../../../Assets/logo_round.png';
// import styles from './subscribe.module.css';
import styles from './components/page-layout/index.module.css';
import ExpressCheckout from './stripeCheckout';
import Checkout from './checkoutStripe';
import StripeElemets from './components/payment-field/StripeElemets';

function Page({ expiryYear, setInputValue, setExpiryYear, setExpiryMonth, setCardNumberStripe, setCvc, cardNumberStripe, cvc, expiryMonth, afflitedDetails, paymentChecker, loader, children, checked, setChecked, navigation, title, subTitle, handleSubmitPay, userEmail, userFirstName, userLastName, setFirstName, setLastName,
  purchasingProduct, setOpen, isDisabled, onContinue, planName, planPrice, promoCode, intervalInDays, planId, promoCodeNew, setPromoCodeNew }) {
      const { getToken } = useContext(AuthContext);
      const [credentialIsValid, setCredentialValidity] = useState(false);
    const location = useLocation();
  const navigate = useNavigate();
  const [nameIsValid, setNameValidity] = useState(false);
  const { allPlans, subscriptionDetails, firstTimeOnlychecked, setFirstTimeOnlyChecked, cardNumber, cvvNumber, expirationDate, isTrail, setAppSnackbar, currentSelectedPlan, setisFromAffl, screatId } = useContext(AppContext);
  const [paymentIsValid, setPaymentValidity] = useState(false);
  const [promoErrMsg, setPromoErrMsg] = useState('');
  const [promoLoading, setPromoLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [termsAgreed, setTermsAgreement] = useState(false);
  const [isOpenModal, setModal] = useState(false);
  const formIsFilled = useMemo(() => (
    credentialIsValid && nameIsValid && paymentIsValid
  ), [credentialIsValid, nameIsValid, paymentIsValid]);
  useEffect(() => {
    const checkValidity = () => {
      const digits = cardNumberStripe.replace(/\D/g, ''); // Remove non-digit characters
      const isAmex = digits.startsWith('34') || digits.startsWith('37');
      const isCvcValid = isAmex ? cvc?.length === 4 : cvc?.length === 3;
      const isCardNumberValid = isAmex ? (digits.length >= 15 && digits.length <= 16) : digits.length === 16;
      const isExpiryValid = expiryMonth?.length > 0;
      return isCvcValid && isCardNumberValid && isExpiryValid;
    };
    setIsFormFilled(checkValidity());
  }, [cvc, cardNumberStripe, expiryMonth]);

  const handleCheckboxChange = (event) => {
    if (location.pathname === '/pay-by-manger') {
      setisFromAffl(true);
    } else {
      setisFromAffl(false);
    }
    const newChecked = event.target.checked;
    setChecked(newChecked);
    setIsFormFilled(newChecked);
    setExpiryMonth('');
    setExpiryYear('');
    setCardNumberStripe('');
    setCvc('');
    setInputValue('');
  };
  useEffect(() => {
  checked === true ? setIsFormFilled(true) : setIsFormFilled(false);
  }, [checked]);

  const onlyFirstTimeCheckbox = () => {
    setFirstTimeOnlyChecked(!firstTimeOnlychecked);
  };
  const fetchResumeData = async () => {
    try {
      const response = await axios.post(`${baseUrl}/sendToAffiliate`, {
        token: getToken(),
        affiliate_email: userEmail
      });
      navigate('/settings/manage-users');
      // setAppSnackbar({ isVisible: true, type: 'error', message: response?.data?.message });
      // setcategoryLoader(false);
    } catch (error) {
      const responseData = error?.response?.data;
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Something went wrong' });
    }
  };
  const fromAffilete = window.location.pathname === '/pay-by-manger';
  return (
    <PageLayout
      navigation={navigation || '/settings/billings-and-payments'}
      isFormFilled={isFormFilled}
      setOpen={setOpen}
      checked={checked}
      paymentChecker={paymentChecker}
      loader={loader}
      userEmail={userEmail}
      continueButtonText={isTrail === '1' ? 'Start Trial' : 'Purchase Now'}
      formIsFilled={formIsFilled}
      onContinue={() => {
        onContinue();
      }}
      purchasingProduct={purchasingProduct}
      subTitle=""
      title={title || 'Start sharing and preserving today'}
      promoErrMsg={promoErrMsg}
      promoCodeNew={promoCodeNew}
      promoLoading={promoLoading}
    >
{' '}
{
            isOpenModal && (
              <InfoModal
                isVisible={isOpenModal}
                title="Allow user to pay themselves."
                onHide={() => { setModal(false); }}
                continueButton="Cancel"
                fromPayment="true"
                // content="You are now deleting, all videos and photos will now be a draft until you
                // Add them to a new category. Are you sure you want to continue?"
                actionButton="Send"
                action={() => fetchResumeData()}
                isDelete
              />
            )
          }
      {purchasingProduct && <RotatingLoader />}
      <div className={styles.flexicos}>
        {false && (
      <Box
          className={styles.paymentFieldInputs}
      >
          <FormSection
            title={subTitle || 'Account Information'}
          >
            {(navigation === '/settings/manage-users' || subscriptionDetails?.paymentMethods?.length > 0)
              && (
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        style={{
                          color: '#1172D2',
                        }}
                        checked={checked}
                        onChange={handleCheckboxChange}
                      />
                    )}
                    label={navigation === '/settings/manage-users' ? 'Use your account information' : 'Use your default card'}
                  />
                  {navigation === '/settings/manage-users'
                  && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        style={{
                          color: '#1172D2',
                        }}
                        checked={firstTimeOnlychecked}
                        onChange={onlyFirstTimeCheckbox}
                      />
                    )}
                    label={navigation === '/settings/manage-users' && 'Only for first year'}
                  />
                  )}
                </FormGroup>
               )}
            <NameField
              checked={checked}
              afflitedDetails={afflitedDetails}
              userFirstName={userFirstName}
              userLastName={userLastName}
              onFirstNameChange={setFirstName}
              onLastNameChange={setLastName}
              setValidity={setNameValidity}
              isDisabled
            />
            <CredentialField
              checked={checked}
              afflitedDetails={afflitedDetails}
              userEmail={userEmail}
              setValidity={setCredentialValidity}
            />
            {checked === false || checked === undefined ? (
              <FormSection title="Payment Information">
                {children}
              </FormSection>
            ) : null}
            <Box>
              <div className={styles.termsContainer}>
                <input
                  hidden
                  id="terms-agreement"
                  onChange={(e) => {
                    setTermsAgreement(e.target.checked);
                  }}
                  type="checkbox"
                  checked={termsAgreed}
                />

                <div
                  onKeyPress={undefined}
                  role="button"
                  tabIndex={0}
                  className={cn({
                    [styles.termsCheckbox]: true,
                    [styles.checked]: termsAgreed,
                  })}
                  onClick={() => {
                    setTermsAgreement(!termsAgreed);
                  }}
                >
                  {termsAgreed && (
                    <CheckRounded className={styles.termsCheckboxIcon} />
                  )}
                </div>
                <span className={styles.terms}>
                  <label htmlFor="terms-agreement">I agree to the </label>
                  {/* <a onClick={handleTandC}>Projector Terms of Service</a> */}
                  <Link target="_blank" to={Pathname.getSettingsPath(Pathname.termsAndConditions)}>Projector Terms of Service</Link>
                  {/* <NextLink href="#">
              </NextLink> */}
                </span>
              </div>
              <div className={styles.flexMe}>
                <button
                  className={styles.continueButton}
                  disabled={!isFormFilled || loader || purchasingProduct || !termsAgreed || (promoErrMsg && promoCodeNew?.length > 0) || promoLoading || (promoCodeNew?.length > 0 && promoCodeNew?.length < 6)}
                  onClick={onContinue}
                >
                  {loader === true
                    ? (
                      <svg className="loading-circle_mini" width="20" height="20" viewBox="0 0 50 50">
                        <circle className="circle" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
                      </svg>
                    ) : purchasingProduct && isTrail === '1' ? 'Starting Trial' : purchasingProduct ? 'Purchasing Plan' : isTrail === '1' ? 'Start Trial' : 'Purchase Now'}
                </button>
                {/* <ExpressCheckout /> */}
                {location.pathname === '/pay-by-manger' && !cardNumber && !cvvNumber && !expirationDate ? (
                  <button
                    style={{ display: checked === true && 'none' }}
                    className={styles.continueButton}
                    onClick={() => setModal(true)}
                    disabled={isFormFilled}
                  // type="submit"
                  >
                    Let customer pay
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </Box>
          </FormSection>
      </Box>
        )}

        <PriceSectionLayout
          setModal={setModal}
          handleCheckboxChange={handleCheckboxChange}
          navigation={navigation}
          planName={planName}
          checked={checked}
          planPrice={fromAffilete ? currentSelectedPlan?.amount : allPlans?.find((plan) => plan?.id === planId)?.amount || allPlans[0]}
          selectedPlan={allPlans?.find((plan) => plan?.id === planId)}
          planId={planId}
          availablePlans={allPlans}
          allPlans={fromAffilete ? currentSelectedPlan : allPlans[0]}
          promoCode={afflitedDetails?.code || afflitedDetails?.affiliateCode}
          intervalInDays={fromAffilete ? currentSelectedPlan?.price_description : intervalInDays}
          promoErrMsg={promoErrMsg}
          setPromoErrMsg={setPromoErrMsg}
          promoCodeNew={promoCodeNew}
          setPromoCodeNew={setPromoCodeNew}
          promoLoading={promoLoading}
          setPromoLoading={setPromoLoading}
        />
        {screatId && (
              <StripeElemets />
        )}
      </div>
    </PageLayout>
  );
}

export default Page;
