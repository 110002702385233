/* eslint-disable no-mixed-operators */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cameraRollIcon from 'Assets/camera-roll.svg';
import viewersIcon from 'Assets/viewers.svg';
// import { Button } from 'Components';
import Switch from '@mui/material/Switch';
import StripeCheckout from 'react-stripe-checkout';
import { config } from 'Config';
import useSWR from 'swr';
import { dataFetcher, endpoints } from 'Api';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import styles from './index.module.css';
import Payment from './Payment';
import { UploadModal } from './updateVideo';
import { ConfirmationModal } from './components/confirmation-modal';
import markWhite from '../../landing-external/assets/img/mark-white.svg';
import markBlack from '../../landing-external/assets/img/mark-black.svg';
import TickCircle from '../../../../Assets/check-circle.png';
import Button from './common/Button';

const Plan = ({
  needToggle,
  hideToggle,
  setIsSwitchPlan,
  isSwitchPlan,
  product_name,
  sub_title,
  amount,
  priceDetails,
  storage,
  viewers,
  price_id,
  setToken,
  setPriceId,
  setTrial_period_days,
  userIsSignedIn,
  isExtended,
  hasTrial,
  totalTrialDays,
  planId,
  contentId,
  contentType,
  freePlanId,
  color,
  description,
  price_description
}) => {
  const { allPlans, setAppSnackbar, subscriptionDetails, plansPage, setPlansPage,
    currentSelectedPlan, setCurrentSelectedPlan, getSubscription, setUserDetails,
    userDetails, langText, setIsTrail, trialVal, setTrial, selectedPlan, selectPlan,
    setAffliatePlanId, screatId, setScreatId } = useContext(AppContext);
  const { forceAutoSignin } = useContext(AuthContext);
  const location = useLocation();
  const planText = langText?.settings?.billPayment?.planList;
  const commonText = langText?.common;
  const navigate = useNavigate();
  const [money, setMoney] = useState(amount);
  const [size, setSize] = useState(storage);
  const [currentPlanBenefit, setCurrentPlanBenefit] = useState('');
  const [isExtra500, setIsExtra500] = useState(false);
  const [isAddOn, setIsAddon] = useState(false);
  const [isDisabledUpgrade, setIsDisabledUpgrade] = useState(false);
  const [planViewer, setPlanViewer] = useState(viewers);
  const [planDescription, setPlanDescription] = useState(description);
  const [PriceDescription, setPriceDescription] = useState(price_description);
  const [data, setUpdateData] = useState();
  const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);
  const hideUploadModal = () => { setUploadModalVisibility(false); };
  const [subscriptionPlanDetails, setSubscriptionPlanDetails] = useState({
    subscriptionPlan: subscriptionDetails?.subscription?.plan,
    plan_price: subscriptionDetails?.plan_price,
    plan_price2: subscriptionDetails?.plan_price2,
    forStandard: price_id,
    myPlanName: subscriptionDetails?.current_plan,
    planaction2: subscriptionDetails?.planaction2,
    planaction: subscriptionDetails?.planaction,
    disk_used_mb: 0,
    total_viewer: 0,
    max_storage_mb: 0,
    subscription: (typeof subscriptionDetails?.subscription === 'string'
      || subscriptionDetails?.subscription instanceof String)
      ? subscriptionDetails?.subscription : undefined,
    id: subscriptionDetails?.subscription?.id,
    status: subscriptionDetails?.subscription?.status,
    degrade: subscriptionDetails?.degrade,
    upgrade: subscriptionDetails?.upgrade,
    isNewUser: subscriptionDetails?.is_newUser,
    isFree: subscriptionDetails?.subscription?.is_free,
    hasActivePlan: subscriptionDetails?.has_activePlan,
    paymentMethods: subscriptionDetails?.paymentMethods,
    isAddonAllowed: subscriptionDetails?.is_addon_allowed,
    amount: subscriptionDetails?.subscription?.amount,
    addonRate: subscriptionDetails?.subscription?.addon_rate,
    totalStorageText: subscriptionDetails?.subscription?.total_storage_text,
    totalStorage: subscriptionDetails?.subscription?.total_storage,
    maxAddonAllowedText: subscriptionDetails?.subscription?.max_add_on_allowed_text,
    maxAddonAllowed: subscriptionDetails?.subscription?.max_add_on_allowed,
    isUnlimited: subscriptionDetails?.subscription?.is_unlimited,
    maxViewRequest: subscriptionDetails?.subscription?.max_view_request,
    paymentProcessing: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 4 && subscriptionDetails?.has_activePlan,
    paymentProcessingText: planText?.paymentProcessing || 'Payment Processing',
    paymentRetry: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 2 && subscriptionDetails?.has_activePlan,
    paymentRetryText: planText?.paymentRetry || 'Payment Retry',
    purchasePlanId: subscriptionDetails?.subscription?.purchase_plan_id
  });
  const [openModal, setModal] = useState(false);

  const currentPlanId = subscriptionPlanDetails.id === planId;

  const checkSize = (size) => {
    const bytes = size * 1048576;
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / (1024 ** i)).toFixed(2))} ${units[i]}`;
  };

  const { isValidating: uploadingVisibility, mutate: updateVisibilityContent } = useSWR([
    contentType === 'video' ? endpoints.addVideoContent : endpoints.addPhotoContent, data
  ], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success, total_count, message }) => {
      if (success) {
        // setUploadModalVisibility(true);
        // setVideoId(undefined);
        if (contentType === 'video') {
          setUserDetails({ ...userDetails, totalVideoUploaded: 1, totalContentUploaded: 1 });
        } else {
          setUserDetails({
            ...userDetails,
            totalContentUploaded: 1
          });
        }
        hideUploadModal();
        // setTimeout(() => { navigate(Pathname.yourVideos); }, 5000);
        setTimeout(() => { navigate(Pathname.yourVideos); }, 500);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },

  });
  useEffect(() => {
    if (data) {
      updateVisibilityContent();
    }
  }, [data]);

  useEffect(() => {
    if ((subscriptionPlanDetails.max_storage_mb / 1000000 === 6) && product_name === 'Premium') {
      setIsDisabledUpgrade(true);
    }
  }, [size]);
  useEffect(() => {
    setSubscriptionPlanDetails({
      subscriptionPlan: subscriptionDetails?.subscription?.plan,
      plan_price: subscriptionDetails?.plan_price,
      plan_price2: subscriptionDetails?.plan_price2,
      forStandard: price_id,
      myPlanName: subscriptionDetails?.current_plan,
      planaction2: subscriptionDetails?.planaction2,
      planaction: subscriptionDetails?.planaction,
      disk_used_mb: subscriptionDetails?.disk_used_mb,
      total_viewer: subscriptionDetails?.total_viewer,
      max_storage_mb: parseInt(subscriptionDetails?.subscription?.max_storage_mb),
      subscription: (typeof subscriptionDetails?.subscription === 'string'
        || subscriptionDetails?.subscription instanceof String)
        ? subscriptionDetails?.subscription : undefined,
      id: subscriptionDetails?.subscription?.id,
      status: subscriptionDetails?.subscription?.status,
      degrade: subscriptionDetails?.degrade,
      upgrade: subscriptionDetails?.upgrade,
      isNewUser: subscriptionDetails?.is_newUser,
      isFree: subscriptionDetails?.subscription?.is_free,
      hasActivePlan: subscriptionDetails?.has_activePlan,
      paymentMethods: subscriptionDetails?.paymentMethods,
      isAddonAllowed: subscriptionDetails?.is_addon_allowed,
      amount: subscriptionDetails?.subscription?.amount,
      addonRate: subscriptionDetails?.subscription?.addon_rate,
      totalStorageText: subscriptionDetails?.subscription?.total_storage_text,
      totalStorage: subscriptionDetails?.subscription?.total_storage,
      maxAddonAllowedText: subscriptionDetails?.subscription?.max_add_on_allowed_text,
      maxAddonAllowed: subscriptionDetails?.subscription?.max_add_on_allowed,
      isUnlimited: subscriptionDetails?.subscription?.is_unlimited,
      maxViewRequest: subscriptionDetails?.subscription?.max_view_request,
      paymentProcessing: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 4 && subscriptionDetails?.has_activePlan,
      paymentProcessingText: planText?.paymentProcessing || 'Payment Processing',
      paymentRetry: parseInt(subscriptionDetails?.subscription?.subscription_status, 10) === 2 && subscriptionDetails?.has_activePlan,
      paymentRetryText: planText?.paymentRetry || 'Payment Retry',
      purchasePlanId: subscriptionDetails?.subscription?.purchase_plan_id
    });
    if (currentPlanId && subscriptionPlanDetails.hasActivePlan) {
      setMoney(parseInt(subscriptionPlanDetails.amount) / 100);
      setSize(subscriptionPlanDetails.totalStorageText);
      setCurrentPlanBenefit(`Add ${subscriptionPlanDetails.maxAddonAllowedText} of storage for 
      $${(subscriptionPlanDetails?.addonRate / 100).toFixed(2)}/month`);
      setPlanViewer(subscriptionPlanDetails?.isUnlimited === '1' ? (planText?.unlimited || 'unlimited') : subscriptionPlanDetails?.maxViewRequest);
    }
  }, [subscriptionDetails]);
  // naming
  const update = () => {
    const formData = new FormData();
    formData.append('video_id', contentId);
    formData.append('visibility', '2');

    setUpdateData(formData);
  };

  const updateAlbumDetails = () => {
    const formData = new FormData();
    formData.append('album_id', contentId);
    setUpdateData(formData);
  };

  const handleChange = (event) => {
    const priceValue = parseFloat(subscriptionPlanDetails.amount) / 100;
    const addon = parseFloat(subscriptionPlanDetails?.addonRate) / 100;
    let space = parseFloat(subscriptionPlanDetails.totalStorage);
    if (isAddOn) {
      setMoney(priceValue);
      setSize(subscriptionPlanDetails.totalStorageText);
    } else {
      setMoney(priceValue + addon);
      space += parseFloat(subscriptionPlanDetails.maxAddonAllowed);
      setSize(checkSize(space));
    }
    setIsAddon(!isAddOn);
  };

  let option;
  const DEGRADE_PLAN = 'Chang';
  const UPGRADE_PLAN = 'Upgrad';
  const isNotFree = subscriptionPlanDetails.subscriptionPlan !== 'Free'
    && subscriptionPlanDetails.subscriptionPlan !== undefined;

  if (subscriptionPlanDetails.degrade?.includes(planId)) {
    option = DEGRADE_PLAN;
  } else if (subscriptionPlanDetails.upgrade?.includes(planId)) {
    option = UPGRADE_PLAN;
  }
  const { isValidating: changingPlan, mutate: changePlan } = useSWR([
    endpoints.switchSubscription, isExtended ? subscriptionPlanDetails.plan_price : subscriptionPlanDetails.forStandard
  ], {
    fetcher: (url, plan_price) => dataFetcher(url, { plan_price }),
    onSuccess: ({ success, }) => {
      if (success) {
        getSubscription();
        setAppSnackbar({
          isVisible: true,
          type: 'success',
          message:
            `You have successfully ${option}ed`
        });

        setPriceId(undefined);
        // setEmail(undefined);
        setToken(undefined);
        setTrial_period_days('0');
        navigate(Pathname.dashboard);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message:
            `Failed to ${option}e`
        });
      }
    },
  });
  const { isValidating: subscribingPlan, mutate: subscribePlan } = useSWR([
    endpoints.subscribePlan, selectedPlan, isAddOn], {
    fetcher: (url, plan_id, is_addon) => dataFetcher(url, { plan_id, trial: trialVal }),
    onSuccess: ({ success, message }) => {
      if (success) {
        setCurrentSelectedPlan(undefined);
        getSubscription();
        forceAutoSignin();
        if (contentId) {
          // navigate(location.pathname);
          if (contentType === 'album') {
            updateAlbumDetails();
          } else {
            update();
          }
        } else {
          setAppSnackbar({
            isVisible: true, type: 'success', message: 'Plan purchased successfully'
          });
          navigate(Pathname.dashboard);
        }
      } else {
        getSubscription();
        forceAutoSignin();
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message,
        });
      }
      setTrial(false);
    },
    onError: (error) => {
      getSubscription();
      forceAutoSignin();
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData || 'Oops! Something went wrong while purchasing plan' });
      setTrial(false);
    },
  });

  const { isValidating: paymentRetrying, mutate: retryPayment } = useSWR([
    endpoints.retryPayment, subscriptionPlanDetails.purchasePlanId], {
    fetcher: (url, subscription_id) => dataFetcher(url, { subscription_id }),
    onSuccess: ({ success, message }) => {
      if (success) {
        getSubscription();
        forceAutoSignin();

        setAppSnackbar({
          isVisible: true,
          type: 'success',
          message,
        });
        setTimeout(() => {
          window.location.replace(Pathname.dashboard);
        }, 3000);
      } else {
        getSubscription();
        forceAutoSignin();
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message,
        });
      }
    },
    onError: (error) => {
      getSubscription();
      forceAutoSignin();
      const responseData = error?.response?.data?.message;
      setAppSnackbar({ isVisible: true, type: 'error', message: (responseData || 'Oops! Something went wrong.') });
    },
  });

  const [open, setOpen] = useState(false);

  const handelToken = (token) => {
    setToken(token.id);
  };

  const [isToken, setIsToken] = useState(undefined);
  useEffect(() => {
    if (isToken) {
      handelToken(isToken);
    }
  }, [isToken]);
  useEffect(() => {
    // if (selectedPlan && subscriptionDetails?.paymentMethods?.length > 0) {
    //   if (contentId && contentType === 'album') {
    //     setUploadModalVisibility(true);
    //   }
    //   subscribePlan();
    // }
  }, [selectedPlan]);

  useEffect(() => {
  }, [price_id]);
  const [selected, setSelected] = useState({ isTrial: undefined, selectedPlan: undefined });

  const handleChangePlan = (id) => {
    selectPlan(id);
  };

  const handleClickOpen = (planName, trial, id) => {
    localStorage.setItem('trial', '0');
    setTrial(false);
    // if (subscriptionDetails?.paymentMethods?.length === 0) {
      setOpen(true);
    // }
    setSelected({ selectedPlan: planName });
    selectPlan(id);
    // if (freePlanId && planId && freePlanId === planId) {
    //   setModal(true);
    // } else if (subscriptionPlanDetails?.degrade?.length === 0 && subscriptionPlanDetails?.upgrade?.length === 0) {
    //   window.location.href = 'https://support.projector.app/hc/en-us';
    // } else {
    //   if (subscriptionDetails?.paymentMethods?.length === 0) { setOpen(true); }
    // }
  };

  const openTrailClick = (planName, id) => {
    setTrial(true);
    localStorage.setItem('trial', '1');
    if (subscriptionDetails?.paymentMethods?.length > 0) {
      setSelected({ selectedPlan: planName });
      selectPlan(id);
      setIsTrail(hasTrial);
    } else {
      selectPlan(id);
      setIsTrail(hasTrial);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (product_name && currentSelectedPlan?.type && currentSelectedPlan?.type === product_name && userIsSignedIn && option === undefined) {
      handleClickOpen(currentSelectedPlan?.selected?.selectedPlan, currentSelectedPlan?.selected?.isTrial, currentSelectedPlan?.planId);
    }
  }, [currentSelectedPlan]);
  const handlePlansPage = (planName, isTrial, planId) => {
    setTrial(false);
    selectPlan(planId);
    setCurrentSelectedPlan({ type: planName, selected: { isTrial, selectedPlan: planName }, planId });
    navigate(Pathname.authentication.newsignIn);
    setPlansPage(true);
  };
  const handleSwitchToggle = async () => {
    setIsSwitchPlan((prev) => (prev === '0' ? '1' : '0'));
  };
  useEffect(() => {
    if (open) {
      window.history.pushState({ page: 1 }, '', window.location.href);
    } else {
      setScreatId(''); // Clear clientSecret when modal is closed
    }
    const handleBackButton = () => {
      if (open) {
        setOpen(false); // Close the modal or perform your logic
        // window.history.pushState({ page: 1 }, '', window.location.href);
      } else {
        window.onpopstate = null; // Remove listener before allowing navigation
        window.history.back(); // Navigate normally
      }
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null; // Cleanup on unmount
    };
  }, [open]);

  return (
<div>
<p className={styles.daysTrails}>
  {totalTrialDays !== '0' && totalTrialDays > 0 && hasTrial === '1' && !userDetails?.trial_used?.includes(planId)
    ? `${totalTrialDays} Day Trial`
    : '\u00A0'}
</p>
    <div className={cn({ [styles.col]: true, [styles.plan_blue]: color === 'blue', [styles.eachPlan]: true })}>
      <UploadModal isVisible={uploadModalIsVisible} onHide={hideUploadModal} contentType={contentType} />
      <>
        {
          openModal && (
            <ConfirmationModal
              isVisible={openModal}
              title={planText?.losingFreeMonthTitle || 'You are losing a free month!'}
              onHide={() => { setModal(false); }}
              cancelButton={commonText?.back || 'Back'}
              continueButton={planText?.continueFreePlan || 'Continue with free plan'}
              content={planText?.losingFreeMonthDescription || 'By selecting a free plan you will lose a free month of Projector. The person who invited you will lose their free two months!'}
            />
          )
        }
      </>

      <div>
        <div className={styles.plan_head}>
          <div className={styles.plan_name}>
            <p>{product_name}</p>
            {hasTrial === '1' && !userDetails?.trial_used?.includes(planId) && (
            <span>
              {totalTrialDays}
              {' '}
              {totalTrialDays === '1' ? 'day ' : 'days '}
              free trial
            </span>
            )}
          </div>

          <div className={styles.plan_price}>
            <p>
              $
              {amount}
            </p>
            <p>{price_description}</p>
          </div>
        </div>

        {description && (
        <ul className={styles.plan_body}>
          {
            description?.split(',').map((feature, index) => (
              <li key={index}>
                <span className={styles.feat}>{feature}</span>
                <img className={styles.tickster} src={TickCircle} alt="" />
              </li>
            ))
          }
        </ul>
        )}
      </div>

      <div className={styles.feature}>
        {(subscriptionPlanDetails.isAddonAllowed && subscriptionPlanDetails.hasActivePlan && currentPlanId) && (
          <>
            <div className={styles.featureIcon}>
              <Radio
                checked={isAddOn}
                onClick={handleChange}
                value="a"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                color="primary"
                style={{ padding: '0' }}
              />
            </div>
            {/* <img alt="viewers" src={viewersIcon} className={styles.featureIcon} /> */}
            <div
              className={cn({ [styles.featureText]: true, [styles.featureTextWhite]: color === 'blue', [styles.featureTextBlue]: color === 'white' })}
            // className={styles.featureText}
            >
              {currentPlanBenefit}
            </div>
          </>
        )}
      </div>

        <div>
        {isDisabledUpgrade && (
        <div className={styles.feature} style={{ textAlign: 'center', width: '100%' }}>
          {/* <div className={styles.featureIcon} /> */}
          {/* <img alt="viewers" src={viewersIcon} className={styles.featureIcon} /> */}
          <div className={cn({ [styles.featureText]: true, [styles.featureTextWhite]: color === 'blue', [styles.featureTextBlue]: color === 'white' })} style={{ fontWeight: '600' }}>Your plan is reached to max</div>
        </div>
      )}
        <>
       {/* {allPlans?.length > 1 && (
        <Switch
            checked={isSwitchPlan === '1'} // Bind switch state
            onChange={handleSwitchToggle}
        />
          )} */}
{ needToggle && (
<div className={styles.toogleAbsolute}>
            {hideToggle !== '3' && (
 <div className={styles.toggleContainer}>
      <div
        className={styles.toggleSwitch}
        onClick={handleSwitchToggle}
      >
              <p className={styles.backText}>Monthly</p>
              <p className={styles.backText}>Annually</p>
        <div
          className={`${styles.toggleButton} ${isSwitchPlan === '1' ? styles.yearly : styles.monthly}`}
        >
          <span className={styles.toggleText}>{isSwitchPlan === '1' ? 'Annually' : 'Monthly' }</span>
        </div>
      </div>
 </div>
          )}
</div>
)}
            <div className={styles.absoluteButton}>
            {hasTrial === '1' && !userDetails?.trial_used?.includes(planId) && (
  <Button
  onClick={() => {
    if (!userIsSignedIn) {
      handlePlansPage(product_name, 'No', planId);
    } else {
      openTrailClick(product_name, planId);
    }
    localStorage.setItem('planId', planId);
  }}
    text={`Start ${totalTrialDays} day trial`}
    btnClass={color === 'blue' ? 'white' : 'blue'}
    isDisabled={(subscribingPlan || (currentPlanId && subscriptionPlanDetails.paymentProcessing) || (!currentPlanId && (subscriptionPlanDetails.paymentProcessing || subscriptionPlanDetails.paymentRetry)) || paymentRetrying || userDetails?.isSubscription) && !subscriptionDetails?.expired}
  />
)}
        {
          isAddOn ? (
            <Button
              onClick={() => (!userIsSignedIn ? handlePlansPage(product_name, 'No', planId) : handleClickOpen(product_name, 'No', planId))}
              text={planText?.purchaseNow || 'Purchase Now'}
              btnClass={color === 'blue' ? 'white' : 'blue'}
            />
          ) : (
            <Button
              // onClick={() => (!userIsSignedIn ? handlePlansPage(product_name, 'No', planId) : (currentPlanId && subscriptionPlanDetails.paymentRetry) ? retryPayment() : handleClickOpen(product_name, 'No', planId))}
              onClick={() => (!userIsSignedIn ? handlePlansPage(product_name, 'No', planId) : handleClickOpen(product_name, 'No', planId))}
              text="Buy Now"
              // link="/sign-up"
              btnClass={styles.buyNow}
              isDisabled={(subscribingPlan || (currentPlanId && subscriptionPlanDetails.paymentProcessing) || (!currentPlanId && (subscriptionPlanDetails.paymentProcessing || subscriptionPlanDetails.paymentRetry)) || paymentRetrying || userDetails?.isSubscription) && !subscriptionDetails?.expired}
            />
          )
        }
            </div>
        <Payment
          setOpen={setOpen}
          open={open}
          handleClose={handleClose}
          price={amount}
          name={product_name}
          selected={selected}
          setIsToken={setIsToken}
          purchasingProduct={subscribingPlan}
          totalTrialDays={totalTrialDays}
          selectedPlan={selectedPlan}
          updateDetails={contentType === 'album' ? updateAlbumDetails : contentType === 'video'
            ? update : null}
          contentId={contentId}
          setUploadModalVisibility={setUploadModalVisibility}
        />
        </>
        </div>
    </div>
</div>
  );
};
export default Plan;
