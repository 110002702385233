/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-line jsx-a11y/no-static-element-interactions
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import moment from 'moment';
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core';
import { dataFetcher, endpoints } from 'Api';
import { VideoPreview } from 'Components';
import { Pathname } from 'Routes';
import cn from 'classnames';
import { AppContext } from 'Context';
import ImageGroup from 'Components/ImageGroup/ImageGroup';
import { ReactComponent as BackIcon } from 'Assets/chevron-left.svg';
import { ReactComponent as PlayIcon } from 'Assets/play.svg';
import WatchlistIcon from 'Assets/watchlistIcon.svg';
import backArrowIcon from 'Assets/backArrowIcon.svg';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

export const VideoDetails = () => {
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { setAppSnackbar, setContextLoading, setVisible, userDetails } = useContext(AppContext);
  const navigate = useNavigate();
  const { videoID } = useParams();
  const [videoDetails, setVideoDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [isVideoInWatchlist, setVideoInWatchlist] = useState(false);
  const [updatingInWatchlist, setWatchlistUpdationStatus] = useState(false);
  const [previewType, setPreviewType] = useState(1);
  const { mutate: updateInWatchlist } = useSWR([endpoints.updateWatchList, videoID, isVideoInWatchlist], {
    fetcher: (url, id, shouldDelete) => dataFetcher(url, {
      video_id: id,
      action: shouldDelete ? 'delete' : 'add',
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setVideoInWatchlist(!isVideoInWatchlist);
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }

      setWatchlistUpdationStatus(false);
    },
    onError: () => {
      setWatchlistUpdationStatus(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: gettingVideos, mutate: getVideoDetails } = useSWR([endpoints.getVideosDetail, videoID], {
    fetcher: (url, id) => dataFetcher(url, { id }),
    onSuccess: ({ success, data, banner_preview_style_id }) => {
      if (success) {
        setVideoDetails(data);
        setVideoInWatchlist(data?.isInWatchlist);
        // setPreviewType(parseInt(banner_preview_style_id, 10));
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      }

      setLoading(false);
    },
    onError: () => {
      setLoading(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
      navigate(Pathname.access);
    },
  });

  useEffect(() => {
    getVideoDetails();
  }, []);
  return (
    <>
      <div className={styles.bgDarkLayer} />
      {loading
          ? (
            <div className={styles.centerCirlce}>
              <CircularProgress />
            </div>
          )
          : (
      <VideoPreview
        className={styles.page}
        containerClassName={styles.pageContainer}
        mainClassName={styles.main}
        noHeader
        noDefaultBg
      >
            <>
              <div className={styles.previewContainer}>
                <div
                  className={cn({ [styles.topContainer]: true, [styles.bgLayer]: true })}
                  // style={{
                  //   backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,1)), url(${videoDetails?.thumbnails?.[0]})`
                  // }}
                >
                  <div className={styles.containerx}>
                    <div className={styles.gradient_overlay} />
                      <img src={videoDetails?.thumbnails?.[0]} className={styles.backImg} alt="" />
                  </div>
                  {/* <div className={styles.backButtonBlock} onClick={() => { setVisible(true); navigate(Pathname.getFriendProfilePath(userDetails?.myId === videoDetails?.user_id ? undefined : videoDetails?.user_id)); setContextLoading(true); }} aria-hidden="true"> */}
                  <div
                    className={styles.backButtonBlock}
                    onClick={() => {
                    setVisible(true);
                    const prevLocation = localStorage.getItem('prevlocation');
                    if (prevLocation === 'innerpage') {
                      // If the previous location is 'innerpage', navigate to the innerpage with the state
                      navigate('/innerpage', {
                        state: {
                          title: videoDetails?.Category,
                          id: videoDetails?.category_id,
                          profile_id: videoDetails?.user_id
                        }
                      });
                    } else {
                      navigate(sessionStorage.getItem('navigateProfile'));
                    }
                    setContextLoading(true);
                    }}
                    aria-hidden="true"
                  >
                    <img
                      className={styles.videoBackButton}
                      src={backArrowIcon || AlterImage}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                  {/* </div> */}
                  <div
                    className={styles.videoDetails}
                  >
                  <div className={styles.videoTitle}>{videoDetails?.title}</div>
                    <div className={styles.videoActions}>
                      <Link to={`${Pathname.getPlayerPath(videoID, 'play')}`} className={styles.videoPlayButton}>
                        <PlayIcon className={styles.videoPlayIcon} />
                        <>Play</>
                      </Link>
                      {
                        videoDetails?.paused_at > 0 ? (
                          <Link
                            to={`${Pathname.getPlayerPath(videoID, 'resumeWatching')}`}
                            className={cn({ [styles.videoPlayButton]: true, [styles.alignButton]: true })}
                            // style={{ marginLeft: '25px' }}
                          >
                            <>Resume Watching</>
                          </Link>
                        ) : null
                      }
                      <button
                        className={styles.videoAddButton}
                        disabled={updatingInWatchlist}
                        onClick={updateInWatchlist}
                      >
                        {isVideoInWatchlist ? '✓' : '+'}
                      </button>
                    </div>
                    <div className={styles.videoInfoWrap}>
                      <div className={styles.videoInfo}>
                        <>{videoDetails?.Category}</>
                        <div className={styles.videoInfoSeparator} />
                        <>{videoDetails?.SubCategory}</>
                      </div>
                      <p className={styles.videoDescription}>{videoDetails?.description}</p>
                    </div>
                  </div>
                  {
                    previewType === 2 ? (
                      <div
                        className={styles.thumbnailDiv}
                        style={{
                          backgroundImage: `linear-gradient(to top right, #000 4%, #121215 8%, #1F1F20 15%, rgba(0,0,0,0)), url(${videoDetails?.thumbnails?.[0]})`
                        }}
                      />
                    ) : null
                  }
                </div>
                <div className={styles.bottomContainer}>
                  {
                    gettingVideos ? (
                      <div className={styles.loadingText}>
                        Loading...
                      </div>
                    ) : (
                      videoDetails?.videos?.length === 0 ? (
                        <div className={styles.loadingText}>
                          No videos
                        </div>
                      ) : (
                        <ImageGroup
                          isPreview
                          isGrid
                          videos={videoDetails?.videos}
                          key={videoDetails?.id}
                          title={videoDetails?.Category}
                        />
                      )
                    )
                  }
                </div>
              </div>
            </>
      </VideoPreview>
          )}
    </>
  );
};
